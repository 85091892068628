import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  lang: string;

  lFlag: boolean = true;

  constructor() { }

  ngOnInit() {

    $(function ($) {

      var demo = {
        init: function () {
          this.carouselControls();

          $(window).resize(function () { demo.carouselControls(); });
        },
        carouselControls: function () {
          var $container = $('#main');
          var $slider = $('#carousel-example-generic');
          var padding = 15;
        }
      };

      demo.init();

    });

  }

}