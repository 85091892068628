import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  lang: string;

  lFlag: boolean = true;

  constructor() { }

  ngOnInit() {

    this.lang = sessionStorage.getItem('language');
    if(this.lang=='ENGLISH'){
        this.lFlag = true;
        }
    else{
        this.lFlag = false;
    } 

    $('.navbar-nav .nav-item').click(function(){
      $('.navbar-nav .nav-item').removeClass('active');
      $(this).addClass('active');
  })

  $('.navbar-nav .nav-link').click(function () {
    $('.navbar-nav .nav-item').removeClass('active');
    $(this).addClass('active');
  })

  $('.navbar-nav>li>a').on('click', function () {
    $('.navbar-collapse').collapse('hide');
  });

  $('.navbar-nav>li>div>a').on('click', function () {
    $('.navbar-collapse').collapse('hide');
  });

    /**
 * Listen to scroll to change navbar opacity class
 */
function checkScroll(){
  var startY = $('.navbar').height() * 5.5; //The point where the menubar changes in px

  if($(window).scrollTop() > startY){
      $('.navbar').addClass("scrolled");
  }else{
      $('.navbar').removeClass("scrolled");
  }
}
  }

  languageChange() {
    if (this.lang == "ENGLISH") {
        sessionStorage.setItem('language', "TAMIL");
        location.reload();
    }
    else {
        sessionStorage.setItem('language', "ENGLISH");
        location.reload();
    }
} 


}
