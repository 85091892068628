import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Premier Real Estates';
  loading: boolean;

  constructor(private router: Router) {

    this.router.events.subscribe((event) => {
        switch (true) {
            case event instanceof NavigationStart: {
                this.loading = true;
                break;
            }
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
                this.loading = false;
                break;
            }
            default: {
                break;
            }
        }
    });

}
  
  onActivate() {
    window.scroll(0,0); 
  }

  

  
}
